export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/register.vue')
  },
  {
    path: '/password',
    name: 'password',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/password.vue')
  }
  
]