import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import Login from './login'
import Common from './common'


Vue.use(VueRouter)

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    component: HomeView,
    children: [
      {
        path: '',
        name: 'index',
        component: () => import('../views/index/index.vue')
      },
      {
        path: '/yq',
        name: 'yq',
        component: () => import('../views/yq/yq.vue')
      },
      {
        path: '/qmzq',
        name: 'qmzq',
        component: () => import('../views/qmzq/qmzq.vue')
      },
      {
        path: '/mine',
        name: 'mine',
        component: () => import('../views/mine/mine.vue')
      },
    ]
  },
  ...Login,
  ...Common

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//切换页面回到顶部
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
})

export default router
